import React from "react";
import Modal from "./modal";
import useModal from "../hooks/useModal";

function Notes({ numero, text }) {
	const { isShowing, toggle } = useModal();

	return (
		<>
			<span
				className="px-2 py-1 text-sm text-white dark:text-black bg-black dark:bg-white inline rounded-full h-8 w-auto mx-1 cursor-pointer"
				onClick={toggle}
			>
				{numero}
			</span>

			<Modal isShowing={isShowing} hide={toggle} text={text} />
		</>
	);
}

export default Notes;
