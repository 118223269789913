import { useState } from "react";

const useModal = () => {
	const [isShowing, setIsShowing] = useState(false);

	function toggle(e) {
		console.log("Modal toggled");
		setIsShowing(!isShowing);
	}

	return {
		isShowing,
		toggle,
	};
};

export default useModal;
