import React from "react";
import ReactDOM from "react-dom";
// import { ClickAwayListener } from "@material-ui/core";

const Modal = ({ isShowing, hide, text }) => {
	const ref = React.useRef();

	React.useEffect(() => {
		const checkIfClickedOutside = (e) => {
			// close if showing
			if (isShowing && ref.current) {
				hide(false);
			}
		};

		const escapeListener = (e) => {
			// close if showing
			if (e.keyCode === 27) {
				// escape
				if (isShowing && ref.current) {
					hide(false);
				}
			}
		};

		document.addEventListener("mousedown", checkIfClickedOutside);
		document.addEventListener("keyup", escapeListener);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside);
			document.removeEventListener("mousedown", escapeListener);
		};
	}, [isShowing]);

	return isShowing
		? ReactDOM.createPortal(
				<React.Fragment>
					<div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" ref={ref}>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

						<div className="fixed z-10 inset-0 overflow-y-auto">
							<div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
								<div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full">
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="text-right mb-3">
											<button
												type="button"
												className="modal-close-button"
												data-dismiss="modal"
												aria-label="Close"
												onClick={hide}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="h-4 w-4 text-gray-600 text-opacity-50"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													strokeWidth={2}
												>
													<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
												</svg>
											</button>
										</div>
										<div className="sm:flex sm:items-start">
											<div dangerouslySetInnerHTML={{ __html: text }} />
										</div>
									</div>
									<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={hide}
										>
											Fermer
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>,
				document.body
		  )
		: null;
};
export default Modal;
