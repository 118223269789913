import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import Notes from "../components/notes";
import { StaticImage } from "gatsby-plugin-image";

// import DOMPurify from "dompurify";

const SuraDetail = ({ data }) => {
	const { directus } = data;
	const { quran_ayat } = data.directus;
	// const { Notes: old_notes } = data.directus;
	// const { en_yusufali } = data.directus;
	// const oldNotesSuraID = [1, 2, 3, 4, 5];
	console.log(directus);
	// console.log(old_notes);
	// console.log(quran_ayat.length);
	// console.log(data.directus.quran_ayat);

	return (
		<Layout pageTitle="Details of Sura">
			<div className="max-w-6xl mx-auto px-2 pb-32">
				<div className="flex justify-center mb-24">
					<div className="bg-detail-box h-64 bg-no-repeat basis-9/12 md:basic-4/12 flex justify-center bg-contain bg-center flex-col items-center">
						<div className="text-l md:text-2xl md:pb-8 flex justify-center flex-col w-1/2 items-center">
							<h1 className="flex-initial pb-1 md:pb-4 justify-center border-b-2 border-slated-800 text-white">
								{quran_ayat[0]["sura"]["latin"]}
							</h1>
							<div className="text-xs md: text-sm text-white pt-2 md:pt-4">{quran_ayat.length} aya</div>
						</div>
						<div className="pt-5 md:pt-0 top-2 flex justify-center items-center h-12">
							<StaticImage
								alt="Bismillah-ir-Rahman-ir-Rahim. Au Nom d’Allah Le Tout Miséricordieux, Le Très Miséricordieux"
								src="../images/bismi.png"
								placeholder="blurred"
								layout="fixed"
								height={48}
							/>
						</div>
					</div>
				</div>

				{quran_ayat.map((item) => (
					<div className="flex flex-col md:flex-row mt-8 border-b-0" key={item.id}>
						<div className="basis-1/3 border-b-0 border-slate-600 mb-2 px-4">
							<div className="">
								<span className="inline cap-first-letter" dangerouslySetInnerHTML={{ __html: item.text_fr }} />
								{item.notes && (
									<>
										{item.notes.map((note) => (
											<span key={note.numero ?? note.Numero} className="inline flex-auto">
												<Notes
													numero={note.numero ?? note.Numero}
													text={note.text ?? note.Text}
													key={note.numero ?? note.Numero}
												/>
											</span>
										))}
									</>
								)}
							</div>
						</div>
						<div
							className="basis-1/3 border-b-0 border-slate-600 mb-2 text-slate-500 dark:text-slate-400 px-4 capitalize-first"
							dangerouslySetInnerHTML={{ __html: item.text_en }}
						/>
						<div
							className="basis-1/3 text-right border-b-0 border-slate-600 my-4 mb-12 md:mb-0 text-2xl px-4"
							dangerouslySetInnerHTML={{ __html: item.text }}
						/>
					</div>
				))}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query SuraDetail($slug: String) {
		directus {
			quran_ayat(filter: { sura: { slug: { _eq: $slug } } }) {
				text_fr
				text_en
				verse_key
				id
				notes
				text
				sura {
					latin
				}
			}
		}
	}
`;

export default SuraDetail;
